
import type { ApiChannel } from "@/twitchautomator.d";
import { defineComponent } from "vue";
import VodItem from "@/components/VodItem.vue";
// import { AxiosError } from "axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faVideo, faPlayCircle, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
library.add(faVideo, faPlayCircle, faVideoSlash);

export default defineComponent({
    name: "StreamerItem",
    emits: ["refresh"],
    props: {
        streamer: Object as () => ApiChannel,
    },
    methods: {
        refresh() {
            this.$emit("refresh");
        },
        async abortCapture() {
            // href="{{ url_for('api_jobs_kill', { 'job': 'capture_' ~ streamer.current_vod.basename }) }}"

            if (!this.streamer || !this.streamer.current_vod) return;

            if (!confirm("Abort record is unstable. Continue?")) return;

            let response;

            try {
                response = await this.$http.delete(`/api/v0/jobs/capture_${this.streamer.current_vod.basename}`);
            } catch (error) {
                if (this.$http.isAxiosError(error)) {
                    console.error("abortCapture error", error.response);
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message);
                    }
                }
                return;
            }

            const data = response.data;

            if (data.message) {
                alert(data.message);
            }

            console.log("Killed", data);
        },
        async forceRecord() {
            if (!confirm("Force record is unstable. Continue?")) return;

            let response;

            try {
                response = await this.$http.get(`/api/v0/channels/${this.streamer?.login}/force_record`);
            } catch (error) {
                if (this.$http.isAxiosError(error)) {
                    console.error("forceRecord error", error.response);
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message);
                    }
                }
                return;
            }

            const data = response.data;

            if (data.message) {
                alert(data.message);
            }

            console.log("Recorded", data);
        },
        async playlistRecord() {
            // href="{{ url_for('api_channel_dump_playlist', { 'username': streamer.display_name }) }}"

            if (!this.streamer || !this.streamer.current_vod) return;

            let response;

            try {
                response = await this.$http.get(`/api/v0/channels/${this.streamer.login}/dump_playlist`);
            } catch (error) {
                if (this.$http.isAxiosError(error)) {
                    console.error("abortCapture error", error.response);
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message);
                    }
                }
                return;
            }

            const data = response.data;

            if (data.message) {
                alert(data.message);
            }

            console.log("Killed", data);
        },
    },
    computed: {
        quality(): string | undefined {
            return this.streamer?.quality.join(", ");
        },
    },
    components: {
        VodItem,
    },
});
