
import { defineComponent } from "vue";

import DurationDisplay from "@/components/DurationDisplay.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFilm, faTachometerAlt, faWrench, faCog, faUserCog, faInfoCircle, faStar, faSync } from "@fortawesome/free-solid-svg-icons";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import { useStore } from "@/store";
import { ApiChannel } from "@/twitchautomator";
library.add(faGithub, faFilm, faTachometerAlt, faWrench, faCog, faUserCog, faInfoCircle, faStar, faSync, faHourglass);

import { nonGameCategories } from "@/defs";

export default defineComponent({
    name: "SideMenuStreamer",
    props: {
        streamer: {
            type: Object as () => ApiChannel,
        },
    },
    setup() {
        const store = useStore();
        return { store, nonGameCategories };
    },
    components: {
        DurationDisplay,
    },
});
