
import { defineComponent } from "vue";

import SideMenu from "@/components/SideMenu.vue";
import { useStore } from "./store";

export default defineComponent({
    name: "App",
    setup() {
        const store = useStore();
        return { store };
    },
    data() {
        return {
            errors: [],
        };
    },
    created() {
        this.store.fetchClientConfig();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            // clear config
            this.store.updateConfig(null);

            let response;

            try {
                response = await this.$http.get(`/api/v0/settings`);
            } catch (error) {
                alert(error);
                return;
            }

            if (response.status !== 200) {
                alert("Non-200 response from server");
                return;
            }

            if (!response.data || !response.data.data) {
                alert("No data received");
                return;
            }

            this.store.updateConfig(response.data.data.config);
            this.store.updateVersion(response.data.data.version);
        },
    },
    components: {
        SideMenu,
    },
});
