
import { defineComponent } from "vue";
export default defineComponent({
    emits: ["close"],
    data() {
        return {
            show: false,
        };
    },
    props: {
        title: {
            type: String,
            default: "Modal",
        },
        // show: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
});
