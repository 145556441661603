
import { defineComponent } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFilm, faTachometerAlt, faWrench, faCog, faUserCog, faInfoCircle, faStar, faSync } from "@fortawesome/free-solid-svg-icons";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import SideMenuStreamer from "./SideMenuStreamer.vue";
import { ApiChannel } from "@/twitchautomator";
import { useStore } from "@/store";
library.add(faGithub, faFilm, faTachometerAlt, faWrench, faCog, faUserCog, faInfoCircle, faStar, faSync, faHourglass);

export default defineComponent({
    components: { SideMenuStreamer },
    name: "SideMenu",
    setup() {
        const store = useStore();
        return { store };
    },
    computed: {
        sortedStreamers() {
            const streamers: ApiChannel[] = this.store.streamerList;
            return streamers.sort((a, b) => a.display_name.localeCompare(b.display_name));
        },
        clientVersion() {
            return process.env.VUE_APP_VERSION; // injected
        },
        verboseClientVersion() {
            return `${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_BUILDDATE} / ${process.env.VUE_APP_GIT_HASH})`; // injected
        },
    },
});
