
import type { ApiJob, ApiVod } from "@/twitchautomator.d";
import { defineComponent, ref } from "vue";
import DurationDisplay from "@/components/DurationDisplay.vue";
// import { format, toDate, parse } from 'date-fns';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faFileVideo,
    faCut,
    faPlay,
    faDatabase,
    faComments,
    faVolumeMute,
    faBurn,
    faTrash,
    faExternalLinkAlt,
    faArchive,
    faDownload,
    faExclamationTriangle,
    faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { useStore } from "@/store";
import ModalBox from "./ModalBox.vue";
library.add(
    faFileVideo,
    faCut,
    faPlay,
    faDatabase,
    faComments,
    faVolumeMute,
    faBurn,
    faTrash,
    faExternalLinkAlt,
    faArchive,
    faDownload,
    faExclamationTriangle,
    faFileSignature
);

export default defineComponent({
    name: "VodItem",
    emits: ["forceFetchData", "refresh"],
    setup() {
        const store = useStore();
        const burnMenu = ref<InstanceType<typeof ModalBox>>();
        return { store, burnMenu };
    },
    data() {
        return {
            config: [],
            taskStatus: {
                vodMuteCheck: false,
                archive: false,
                downloadChat: false,
                renderChat: false,
                downloadVod: false,
                fullBurn: false,
                delete: false,
            },
            burnLoading: false,
            burnSettings: {
                renderChat: false,
                burnChat: false,
                renderTest: false,
                burnTest: false,
                chatWidth: 300,
                chatHeight: 300,
                vodSource: "captured",
                chatSource: "captured",
                chatFont: "Inter",
                chatFontSize: 12,
                burnHorizontal: "left",
                burnVertical: "top",
                ffmpegPreset: "slow",
                ffmpegCrf: 26,
            },
        };
    },
    mounted() {
        if (this.vod) this.burnSettings.chatHeight = parseInt(this.vod.video_metadata_public.video.Height);
    },
    props: {
        vod: Object as () => ApiVod,
    },
    methods: {
        doArchive() {
            if (!confirm(`Do you want to archive "${this.vod?.basename}"?`)) return;
            this.taskStatus.archive = true;
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/save`)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);
                    this.taskStatus.archive = false;
                    this.$emit("refresh");
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                    this.taskStatus.archive = false;
                });
        },
        doDownloadChat() {
            if (!confirm(`Do you want to download the chat for "${this.vod?.basename}"?`)) return;
            this.taskStatus.downloadChat = true;
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/download_chat`)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);
                    this.taskStatus.downloadChat = false;
                    this.$emit("refresh");
                    if (this.vod) this.store.updateVod(this.vod.basename);
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                    this.taskStatus.downloadChat = false;
                });
        },
        // doRenderChat(useVod = false) {
        //     /** @todo: implement */
        //     alert(`RenderChat not implemented: ${useVod}`);
        // },
        doDownloadVod() {
            if (!confirm(`Do you want to download the vod for "${this.vod?.basename}"?`)) return;
            this.taskStatus.downloadVod = true;
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/download`)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);
                    this.taskStatus.downloadVod = false;
                    this.$emit("refresh");
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                    this.taskStatus.downloadVod = false;
                });
        },
        doCheckMute() {
            this.taskStatus.vodMuteCheck = true;
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/check_mute`)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);

                    if (json.data) {
                        if (json.data.muted === null) {
                            alert(`The vod "${this.vod?.basename}" could not be checked.`);
                        } else {
                            alert(`The vod "${this.vod?.basename}" is${json.data.muted ? "" : " not"} muted.`);
                        }
                    }
                    this.taskStatus.vodMuteCheck = false;
                    this.$emit("refresh");
                })
                .catch((err) => {
                    console.error("doCheckMute error", err.response);
                    if (err.response.data) {
                        const json = err.response.data;
                        if (json.message) alert(json.message);
                    }
                    this.taskStatus.vodMuteCheck = false;
                });
        },
        // doFullBurn() {
        //     /** @todo: implement */
        //     alert("FullBurn");
        // },
        doDelete() {
            if (!confirm(`Do you want to delete "${this.vod?.basename}"?`)) return;
            if (this.vod?.twitch_vod_exists === false && !confirm(`The VOD "${this.vod?.basename}" has been deleted from twitch, are you still sure?`)) return;
            this.taskStatus.delete = true;
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/delete`)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);
                    this.taskStatus.delete = false;
                    this.$emit("refresh");
                    if (this.vod) this.store.updateStreamer(this.vod.streamer_login);
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                    this.taskStatus.delete = false;
                });
        },
        doRenderWizard() {
            this.burnLoading = true;
            console.debug("doRenderWizard", this.burnSettings);
            this.$http
                .post(`/api/v0/vod/${this.vod?.basename}/renderwizard`, this.burnSettings)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);
                    this.$emit("refresh");
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                })
                .finally(() => {
                    this.burnLoading = false;
                });
        },
        addFavouriteGame(game_id: number) {
            if (!this.store.config) return;

            let data: { games: Record<number, boolean> } = {
                games: {},
            };

            data.games[game_id] = true;
            for (const fid in this.store.config.favourites) {
                data.games[parseInt(fid)] = true;
            }

            this.$http
                .put(`/api/v0/favourites`, data)
                .then((response) => {
                    const json = response.data;
                    if (json.message) alert(json.message);
                    console.log(json);

                    this.$http.get(`/api/v0/settings`).then((response) => {
                        this.store.updateConfig(response.data.data.config);
                    });
                })
                .catch((err) => {
                    console.error("form error", err.response);
                    if (err.response.data && err.response.data.message) alert(err.response.data.message);
                });
        },
        playerLink(offset = 0, chatdownload = false): string {
            if (!this.store.config) return "#";
            let video_path = `${this.vod?.webpath}/${this.vod?.basename}.mp4`;
            let chat_path = `${this.vod?.webpath}/${this.vod?.basename}.${chatdownload ? "chat" : "chatdump"}`;
            return `${this.store.cfg("basepath")}/vodplayer/index.html#source=file_http&video_path=${video_path}&chatfile=${chat_path}&offset=${offset}`;
        },
    },
    computed: {
        compDownloadChat(): boolean {
            if (!this.store.jobList) return false;
            for (let job of this.store.jobList) {
                if (job.name == `tcd_${this.vod?.basename}`) {
                    return true;
                }
            }
            return false;
        },
        hasViewerCount(): boolean {
            if (!this.vod) return false;
            if (!this.vod.chapters) return false;
            return this.vod.chapters.some((chapter) => {
                return chapter.viewer_count > 0;
            });
        },
        burnJobs(): ApiJob[] {
            if (!this.store.jobList) return [];
            let jobs: ApiJob[] = [];
            for (let job of this.store.jobList) {
                if (job.name == `tdrender_${this.vod?.basename}` || job.name == `burnchat_${this.vod?.basename}`) {
                    jobs.push(job);
                }
            }
            return jobs;
        },
        burnPreviewChat(): Record<string, string> {
            if (!this.vod) return {};
            return {
                width: `${(this.burnSettings.chatWidth / parseInt(this.vod.video_metadata_public.video.Width)) * 100}%`,
                height: `${(this.burnSettings.chatHeight / parseInt(this.vod.video_metadata_public.video.Height)) * 100}%`,
                left: this.burnSettings.burnHorizontal == "left" ? "0" : "",
                right: this.burnSettings.burnHorizontal == "right" ? "0" : "",
                top: this.burnSettings.burnVertical == "top" ? "0" : "",
                bottom: this.burnSettings.burnVertical == "bottom" ? "0" : "",
                fontSize: `${this.burnSettings.chatFontSize * 0.35}px`,
                fontFamily: this.burnSettings.chatFont,
            };
        },
    },
    components: {
        DurationDisplay,
        ModalBox,
    },
});
